import React from "react";
import Navbar from "./Navbar";
// import appImg from "../assets/get the app.webp";
import appImg from "../assets/imgapp2.png";
function Header({ isOpen, setOpen }) {
  return (
    <header
      id="home"
      className="bg-gradient-color"
      style={
        {
          // backgroundImage: `linear-gradient(180deg,#5FDDD1,transparent)`,
        }
      }
    >
      <Navbar isOpen={isOpen} setOpen={setOpen} />
      <div className="container sm:px-10 lg:p-5 mx-auto flex flex-col-reverse md:flex-row w-full justify-between items-center">
        <section
          className={`text-sect w-full lg:w-1/2 h-[100vh] md:h-[80vh] lg:h-auto flex justify-center items-center md:mt-[150px] lg:mt-0 bg-appimg lg:bg-none bg-no-repeat bg-center rounded-none sm:rounded-3xl md:shadow-2xl lg:shadow-none overflow-hidden`}
        >
          <div className="p-5 w-full h-full flex justify-center items-center backdrop-blur-[8px] bg-[#f0eded2a] md:bg-transparent">
            <div className="tracking-[3px] text-black">
              <h3 className="text-[30px] md:text-[50px] text-center md:text-start font-bold">
                Your health <br /> matters to us
              </h3>
              <p className="font-bold text-[20px] text-center md:text-start px-0 border-b-2 w-fit border-b-[#08AFB5]">
                Get{" "}
                <span className="text-[#0BA5AB] text-[30px] font-bold">
                  Life Care
                </span>{" "}
                App
              </p>
            </div>
          </div>
        </section>
        <section className="hidden lg:block">
          <img src={appImg} alt="life care app" />
        </section>
      </div>
    </header>
  );
}

export default Header;
