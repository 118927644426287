import React from "react";
import Hamburger from "hamburger-react";
import logo from "../assets/cleanLogo.svg";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  console.log({ location });
  return (
    <nav className={`fixed top-0 z-10 w-full backdrop-blur-lg bg-[#fafafac0] `}>
      <div className="flex justify-between items-center container mx-auto overflow-visible px-5 py-2">
        <img
          className="z-20 w-[60px] h-[60px] "
          src={logo}
          alt="life care"
          width={"150px"}
          height={"80px"}
        />
        <ul className="hidden md:flex justify-center items-center gap-10 px-6 py-3 rounded-md">
          {location.pathname == "/" ? (
            <li>
              <a href="#home">Home</a>
            </li>
          ) : (
            <li>
              <Link to={"/"}>Home</Link>
            </li>
          )}
          {location.pathname == "/" && (
            <li>
              <a href="#advances">Advances</a>
            </li>
          )}
          {location.pathname == "/" && (
            <li>
              <a href="#services">Services</a>
            </li>
          )}
          {location.pathname == "/" && (
            <li>
              <a href="#getApp">Get App</a>
            </li>
          )}
          {(location.pathname == "/about" ||
            location.pathname == "/privacy" ||
            location.pathname == "/terms") && (
            <>
              <li>
                <Link to={"/about"}>About us</Link>
              </li>
              <li>
                <Link to={"/privacy"}>Privacy</Link>
              </li>
              <li>
                <Link to={"/terms"}>Terms</Link>
              </li>
            </>
          )}
        </ul>
        <button className="p-2 bg-app-orange text-white rounded-md">
          <a href="#contact"> Contact us</a>
        </button>
        {/* <button className={`block md:hidden  rounded-md`}>
          <Hamburger
            color="white"
            toggled={isOpen}
            toggle={setOpen}
            size={20}
          />
        </button> */}
      </div>
    </nav>
  );
}

export default Navbar;
