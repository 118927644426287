import React from "react";

function Card({ icon, title, desc, color, translate, coloredTitle }) {
  return (
    <div
      style={{ background: color }}
      className={`flex flex-col justify-around rounded-3xl items-center w-[210px] h-[210px] ${
        translate && "md:mt-10"
      } p-3 tracking-[0px]`}
    >
      <img src={icon} alt="nurse" width={50} height={50} />
      {/* <div className="text-center"> */}
      <h3
        className={`text-lg font-bold ${
          coloredTitle ? "text-[#08AFB5]" : "text-white"
        } `}
      >
        {title}
      </h3>
      <p className="text-center text-sm">{desc}</p>
      {/* </div> */}
    </div>
  );
}

export default Card;
