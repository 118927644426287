import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";
import ScrollToTop from "./ScrollToTop";

function Privacy() {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`https://insta-integration.appssquare.com/api/privacy`)
      .then((res) => setData(res.data.data.privacy_policy));
  }, []);

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="about_us pt-[100px] pb-10 container px-5 mx-auto">
        {data ? (
          <>
            <div className="w-fit">
              <h3 className="text-2xl font-bold text-[#222928]">Our Privacy</h3>
              <div className="underLine w-full h-1"></div>
            </div>
            <br />
            <article className="text-slate-800 text-base md:text-lg text-justify tracking-wider">
              <div dangerouslySetInnerHTML={{ __html: data }} />
            </article>
          </>
        ) : (
          <div className="h-[70vh] flex justify-center items-center ">
            <PacmanLoader color="#3F4B5B" />
          </div>
        )}
      </div>
    </>
  );
}

export default Privacy;
