import React from "react";
import appImg from "../assets/img4.webp";
import googleImg from "../assets/gPlay.svg";
import appStore from "../assets/appStore.svg";
function Section3() {
  return (
    <div className="section-3 mb-32 lg:mb-96 container lg:max-w-full lg:mt-24 lg:h-[400px] sm:px-10 lg:p-5 mx-auto flex flex-col-reverse lg:flex-row w-full justify-between items-center">
      <section className="hidden lg:block" id="getApp">
        <img src={appImg} alt="life care app" width={"90%"} />
      </section>
      <section
        className={`text-sect w-full lg:w-1/2 h-[100vh] lg:h-auto flex justify-center items-center mt-[150px] lg:mt-0 bg-appimg4 lg:bg-none bg-no-repeat bg-center rounded-none sm:rounded-3xl md:shadow-2xl lg:shadow-none overflow-hidden`}
      >
        <div className="p-5 w-full h-full flex justify-center items-center backdrop-blur-[8px] bg-[#f0eded2a] md:bg-transparent">
          <div className="tracking-[3px] text-black">
            <h3 className="text-center lg:text-start text-[#2B4D66] text-[30px] md:text-[50px] font-bold">
              Get the app
            </h3>
            <p className="tracking-[1px] md:tracking-[3px] text-center lg:text-start">
              Our app is designed to provide a great healthcare experience with
              peace of mind. Download now to take control of your health.
            </p>
            <div className="mt-5 flex flex-col justify-center items-center md:flex-row lg:justify-start gap-3">
              <img width={180} src={googleImg} alt="Google play" />
              <img width={180} src={appStore} alt="App store" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section3;
