import React from "react";

function Aside({ isOpen, setOpen }) {
  return (
    <aside
      className={`${
        isOpen ? "fixed" : "hidden"
      } z-10 border border-red-300 w-full h-full flex flex-col justify-center items-center gap-10 backdrop-blur-3xl bg-[#ffffff83]`}
    >
      <ul className="flex flex-col justify-center w-full items-center gap-10 px-6 py-3 rounded-md">
        <li
          onClick={() => setOpen(false)}
          className="border bg-[#3F4B5B] text-white rounded-lg p-2 w-[70%] text-center"
        >
          <a href="#home">Home</a>
        </li>
        <li
          onClick={() => setOpen(false)}
          className="border bg-[#3F4B5B] text-white rounded-lg p-2 w-[70%] text-center"
        >
          <a href="#advances">Advances</a>
        </li>
        <li
          onClick={() => setOpen(false)}
          className="border bg-[#3F4B5B] text-white rounded-lg p-2 w-[70%] text-center"
        >
          <a href="#services">Services</a>
        </li>
        <li
          onClick={() => setOpen(false)}
          className="border bg-[#3F4B5B] text-white rounded-lg p-2 w-[70%] text-center"
        >
          <a href="#getApp">Get App</a>
        </li>
      </ul>
    </aside>
  );
}

export default Aside;
