import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-hot-toast";

function Contact() {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Please Enter Your Name"),
    message: yup.string().required("Please Enter A Message"),
    email: yup
      .string()
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        "Please Enter A Valid Email"
      )
      .required("req"),
  });
  const [data, setData] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  console.log({ data });

  return (
    <div className="contact container mb-28 lg:max-w-full lg:mt-28 min-h-28 lg:h-[300px] sm:px-10 lg:p-5 mx-auto flex justify-center items-center lg:items-end w-full">
      <section
        id="contact"
        className={`p-12 w-full lg:w-auto translate-y-16 bg-white flex justify-center items-center lg:mt-0 lg:bg-none bg-no-repeat bg-center rounded-none sm:rounded-xl shadow-2xl drop-shadow-2xl overflow-hidden`}
      >
        <div className="p-5 w-full h-full flex flex-col justify-center items-center gap-10 backdrop-blur-[1px] md:bg-transparent">
          <h3 className="text-xl md:text-4xl font-bold tracking-widest ">
            Quick Contact
          </h3>
          <p className="text-base font-semibold text-gray-500">
            Leave us a message
          </p>
          <form
            className="flex flex-col justify-center items-center gap-8"
            onSubmit={handleSubmit((data) =>
              axios
                .post(
                  `https://insta-integration.appssquare.com/api/contact-us`,
                  data
                )
                .then((res) => {
                  toast.success(res.data.message);
                  reset();
                })
                .catch((err) => {
                  toast.error(err.response.data.message);
                  reset();
                })
            )}
          >
            <div className="flex flex-col justify-start relative ">
              <label htmlFor="name" className="text-xs text-gray-900">
                Name
              </label>
              <input
                id="name"
                className="max-w-[90vw] text-sm outline-none border border-gray-200 p-2 rounded w-[500px] h-[30px] bg-transparent"
                {...register("name", { required: true })}
                placeholder="Your name"
              />
              {errors.name && (
                <p className="error_message absolute -bottom-5 text-xs text-red-600">
                  {errors.name.message}
                </p>
              )}
            </div>
            <div className="flex flex-col justify-start relative">
              <label htmlFor="email" className="text-xs text-gray-900">
                Email
              </label>
              <input
                id="email"
                className="max-w-[90vw] text-sm outline-none border border-gray-200 p-2 rounded w-[500px] h-[30px] bg-transparent"
                type="email"
                {...register("email")}
                placeholder="Your email"
              />
              {errors.email && (
                <p className="error_message absolute -bottom-5 text-xs text-red-600">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="flex flex-col justify-start relative">
              <label htmlFor="email" className="text-xs text-gray-900">
                Message
              </label>
              <textarea
                id="message"
                className="max-w-[90vw] text-sm outline-none border border-gray-200 p-2 rounded w-[500px] bg-transparent"
                {...register("message")}
                placeholder="Your message"
              />
              {errors.message && (
                <p className="error_message absolute -bottom-5 text-xs text-red-600">
                  {errors.message.message}
                </p>
              )}
            </div>
            <button
              className="max-w-[90vw] text-lg font-bold outline-none w-[500px] h-[45px] bg-[#E8760D] text-white rounded"
              type="submit"
            >
              Send
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
