import React from "react";
import appImg from "../assets/img2.webp";
import nurse from "../assets/nurse.svg";
import cross from "../assets/cross.webp";
import Card from "./Card";
function Section1() {
  return (
    <div
      id="advances"
      className="container sm:px-10 lg:p-5 mx-auto flex flex-col-reverse md:flex-row w-full justify-between items-center"
    >
      <section className="hidden lg:block">
        <img src={appImg} alt="life care app" />
      </section>
      <section
        className={`text-sect w-full lg:w-1/2 h-full md:h-[80vh] lg:h-auto flex justify-center items-center mt-[150px] lg:mt-0 bg-appimg2 lg:bg-none bg-no-repeat bg-center rounded-none sm:rounded-3xl md:shadow-2xl lg:shadow-none overflow-hidden`}
      >
        <div className="p-5 w-full h-full flex justify-center items-center backdrop-blur-[8px] bg-[#f0eded2a] md:bg-transparent">
          <div className="tracking-[3px] text-black">
            <h3 className="text-center lg:text-start text-[20px] md:text-[50px] font-bold">
              Find A <br />
              Doctor Specialty
            </h3>
            <p className="tracking-[1px] md:tracking-[3px] text-center lg:text-start">
              Easily find the perfect doctor for your needs with our specialty
              search feature.
            </p>
            <div className="flex flex-col md:flex-row mt-5 justify-center gap-5 items-center">
              <Card
                icon={nurse}
                title={"Telemedicine Care"}
                desc={
                  "Get specialized medical care from the comfort of your own home with Telemedicine."
                }
                color={"#5FDDD1"}
                coloredTitle={false}
              />
              <Card
                icon={cross}
                title={"Emergency Care"}
                desc={
                  "Immediate medical attention for emergencies with our specialized care services."
                }
                color={"rgba(239, 245, 255)"}
                translate={true}
                coloredTitle={true}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section1;
