import { useEffect, useState } from "react";
import "./App.css";
import Aside from "./components/Aside";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import About from "./components/About.jsx";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";

function App() {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    const body = document.querySelector("body");
    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isOpen]);
  return (
    <>
      <Toaster />
      <Aside isOpen={isOpen} setOpen={setOpen} />
      <div
        className="App"
        style={{
          backgroundImage: `linear-gradient(180deg,#5FDDD1,transparent)`,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header isOpen={isOpen} setOpen={setOpen} />
                <Section1 />
                <Section2 />
                <Section3 />
                <Contact />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
