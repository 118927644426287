import React from "react";
import appImg from "../assets/img3.webp";
import snake from "../assets/snake.svg";
import Capsule from "../assets/Capsule .svg";
function Section2() {
  return (
    <div
      id="services"
      className="container sm:px-10 lg:p-5 mx-auto flex flex-col-reverse md:flex-row w-full justify-between items-center"
    >
      <section
        className={`text-sect w-full lg:w-1/2 min-h-[80vh] lg:h-auto flex justify-center items-center mt-[150px] lg:mt-0 bg-appimg3 lg:bg-none bg-no-repeat bg-center rounded-none sm:rounded-3xl md:shadow-2xl lg:shadow-none overflow-hidden`}
      >
        <div className="p-5 w-full min-h-[80vh] md:h-full flex justify-center items-center backdrop-blur-[8px] bg-[#f0eded2a] md:bg-transparent">
          <div className="tracking-[3px] text-black">
            <h3 className="text-center lg:text-start text-[50px] font-bold">
              Book a Service
            </h3>
            <p className="text-center lg:text-start">
              Experience a new level of convenience and flexibility with our
              app's innovative healthcare booking feature.
            </p>
            <div className="mt-5 flex flex-col justify-center items-center lg:items-start">
              <div className="w-[300px] p-2 bg-[#EFF5FFC7] flex gap-2 shadow-lg rounded-md justify-start items-center">
                <img src={snake} alt="Home Visits" />
                <div>
                  <h5 className="text-sm font-bold text-[#08AFB5]">
                    Home Visits
                  </h5>
                  <p className="text-[#2B4D66] text-xs font-light">
                    Get medical care at your own home with Home Visits feature.
                  </p>
                </div>
              </div>
              <div className="w-[300px] p-2 mt-3 bg-white flex gap-2 shadow-lg rounded-md justify-start items-center">
                <img src={Capsule} alt="Emergency Care" />
                <div>
                  <h5 className="text-sm font-bold text-[#08AFB5]">
                    Tele-Medicine
                  </h5>
                  <p className="text-[#2B4D66] text-xs font-light">
                    Experience virtual healthcare easily with Tele-Medicine
                    feature.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hidden lg:block">
        <img src={appImg} alt="life care app" />
      </section>
    </div>
  );
}

export default Section2;
