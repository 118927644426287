import React, { useEffect } from "react";

function ScrollToTop() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return <div></div>;
}

export default ScrollToTop;
