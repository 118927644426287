import React from "react";
import logo from "../assets/cleanLogo.svg";
import googleImg from "../assets/gPlay.svg";
import appStore from "../assets/appStore.svg";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className="text-[#09101D] bg-[#EFEFEF]">
      <div className="container mx-auto p-5 lg:px-16 flex flex-col md:flex-row justify-center items-center md:items-start">
        <div className="w-full gap-2 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center md:items-start p-3">
            <img src={logo} alt="life care" width={50} />
            <p className="text-center md:text-start text-sm font-medium">
              Life Care is the all-in-one medical app that simplifies your
              healthcare journey. Find doctors, manage medications, and monitor
              your health with ease.
            </p>
          </div>
        </div>
        <div className="w-full p-3 gap-2 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center md:items-start">
            <p className="font-bold mb-3">Main Pages</p>
            <ul className="flex md:flex-col gap-3 flex-wrap justify-center">
              <li className="text-sm font-medium">
                <a href="#home">Home</a>
              </li>
              <li className="text-sm font-medium">
                <a href="#advances">Advances</a>
              </li>
              <li className="text-sm font-medium">
                <a href="#services">Services</a>
              </li>
              <li className="text-sm font-medium">
                <a href="#getApp">Get App</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full p-3 gap-2 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center md:items-start">
            <p className="font-bold mb-3">Links</p>
            <ul className="flex md:flex-col gap-3 flex-wrap justify-center">
              <li className="text-sm font-medium">
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li className="text-sm font-medium">
                <Link to="/privacy">Privacy & Policy</Link>
              </li>
              <li className="text-sm font-medium">
                <Link to="/about">About Us</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full p-3 gap-2 flex flex-col justify-center items-center">
          <p className="text-lg font-bold">Download the App</p>
          <div className="flex flex-col gap-2">
            <img width={150} src={googleImg} alt="Google play" />
            <img width={150} src={appStore} alt="App store" />
          </div>
        </div>
      </div>
      <div className="bg-[#3F4B5B] text-sm text-white text-center py-2">
        copyright Apps Square. All right reserved
      </div>
    </footer>
  );
}

export default Footer;
